import React from 'react';
import { Dropdown as AntdDropdown } from 'antd';
import classNames from 'classnames';

import Icon from '@/components/Icon';

import { TDropdownMenuProps } from './DropdownMenu.types';
import './DropdownMenu.scss';

const DropdownMenu: React.FC<TDropdownMenuProps> = ({
  children,
  trigger,
  placement,
  overlayClassName,
  options = [],
  disabled,
  onVisibleChange,
}) => {
  const handleVisibleChange = (currentVisible: boolean): void => {
    onVisibleChange?.(currentVisible);
  };

  const antdDropdownProps = {
    placement,
    disabled,
    getPopupContainer: (container: HTMLElement): HTMLElement => container,
    overlayClassName: classNames('DropdownMenu-overlay', overlayClassName),
    trigger: trigger || ['click'],
    onVisibleChange: handleVisibleChange,
  };

  const renderDropdownMenuOverlay = (): React.ReactElement => {
    return (
      <div className="DropdownMenu-list">
        {options
          .filter((item) => !item.hide)
          .map((item) =>
            item?.line ? (
              <div className="DropdownMenu-list-line" />
            ) : (
              <div
                key={item.value}
                className={classNames(
                  'DropdownMenu-list-item flex items-center',
                  { danger: item?.danger },
                  { disabled: item?.disabled },
                )}
                style={{ cursor: item.disabled ? 'not-allowed' : 'pointer' }}
                onClick={(): void => {
                  if (!item?.disabled) item.onClick?.();
                }}
              >
                {item?.iconName && (
                  <div className="DropdownMenu-list-item-icon">
                    <Icon name={item.iconName} color={item.iconColor} />
                  </div>
                )}
                <div className="DropdownMenu-list-item-label">{item.label}</div>
              </div>
            ),
          )}
      </div>
    );
  };

  return (
    <div className="DropdownMenu">
      <AntdDropdown {...antdDropdownProps} overlay={renderDropdownMenuOverlay()}>
        <div className="DropdownMenu-wrapper">{children}</div>
      </AntdDropdown>
    </div>
  );
};

export default DropdownMenu;
