import { createActionCreator } from 'deox';

import { TVovLoginMaterials, TVovLoginResponse } from '@/services/vov-api/vov-auth/vov-login';

// CONSTANTS

export enum EVovLoginAction {
  VOV_LOGIN = 'VOV_LOGIN',
  VOV_LOGIN_REQUEST = 'VOV_LOGIN_REQUEST',
  VOV_LOGIN_SUCCESS = 'VOV_LOGIN_SUCCESS',
  VOV_LOGIN_FAILED = 'VOV_LOGIN_FAILED',
}

// TYPES

export type TVovLoginRequest = {
  type: EVovLoginAction.VOV_LOGIN_REQUEST;
  payload: {
    materials: TVovLoginMaterials;
    successCallback?: (response: TVovLoginResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVovLoginSuccess = {
  type: EVovLoginAction.VOV_LOGIN_SUCCESS;
  payload: { response: TVovLoginResponse };
};

export type TVovLoginFailed = { type: EVovLoginAction.VOV_LOGIN_FAILED };

// FUNCTION

export const vovLoginAction = {
  request: createActionCreator(
    EVovLoginAction.VOV_LOGIN_REQUEST,
    (resolve) =>
      (
        materials: TVovLoginMaterials,
        successCallback?: (response: TVovLoginResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVovLoginRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EVovLoginAction.VOV_LOGIN_SUCCESS,
    (resolve) =>
      (response: TVovLoginResponse): TVovLoginSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EVovLoginAction.VOV_LOGIN_FAILED,
    (resolve) =>
      (error: unknown): TVovLoginFailed =>
        resolve({ error }),
  ),
};
