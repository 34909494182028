import { createReducer } from 'deox';

import { TVovDecodeStringResponse, TVovGetMyProfileResponse, TVovLoginResponse } from '@/services/vov-api/vov-auth';
import { vovDecodeStringAction, vovGetMyProfileAction, vovLoginAction } from '@/redux/actions';
import { vovDecodeStringUpdateState } from './vov-decode-string';
import { vovGetMyProfileUpdateState } from './vov-get-my-profile';
import { vovLoginUpdateState } from './vov-login';

export type TVovAuthState = {
  vovDecodeStringResponse?: TVovDecodeStringResponse;
  vovGetMyProfileResponse?: TVovGetMyProfileResponse;
  vovLoginResponse?: TVovLoginResponse;
};

const initialState: TVovAuthState = {
  vovDecodeStringResponse: undefined,
  vovGetMyProfileResponse: undefined,
  vovLoginResponse: undefined,
};

const VovAuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(vovDecodeStringAction.success, vovDecodeStringUpdateState),
  handleAction(vovGetMyProfileAction.success, vovGetMyProfileUpdateState),
  handleAction(vovLoginAction.success, vovLoginUpdateState),
]);

export default VovAuthReducer;
