import VovApiService from '@/services/vov-api';

// TYPES

export type TVovDecodeStringParams = {
  kw?: string;
};

export type TVovDecodeStringMaterials = {
  params?: TVovDecodeStringParams;
};

export type TVovDecodeStringResponse = unknown;

// FUNCTION

export const vovDecodeString = async ({ params }: TVovDecodeStringMaterials): Promise<TVovDecodeStringResponse> => {
  const response = await VovApiService.get(`/api/test/de`, { params });
  return response?.data;
};
