import { all, fork } from 'redux-saga/effects';

import authSaga from './auth';
import categorySaga from './category';
import recordSaga from './record';
import vovAuthSaga from './vov-auth';

const rootSaga = function* root(): Generator {
  yield all([fork(authSaga), fork(categorySaga), fork(recordSaga), fork(vovAuthSaga)]);
};

export default rootSaga;
