import VovApiService from '@/services/vov-api';

// TYPES

export type TVovLoginParams = unknown;
export type TVovLoginBody = unknown;

export type TVovLoginMaterials = {
  params?: TVovLoginParams;
  body?: TVovLoginBody;
};

export type TVovLoginResponse = {
  allow: boolean;
  message: string;
  userid: string;
};

// FUNCTION

export const vovLogin = async ({ params, body }: TVovLoginMaterials): Promise<TVovLoginResponse> => {
  const response = await VovApiService.post(`/api/voice2text/login`, body, { params });
  return response?.data;
};
