import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { vovDecodeStringAction } from '@/redux/actions';
import { vovDecodeString, TVovDecodeStringResponse } from '@/services/vov-api';

// FUNCTION

export function* vovDecodeStringSaga(action: ActionType<typeof vovDecodeStringAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(vovDecodeString, materials);
    const vovDecodeStringResponse: TVovDecodeStringResponse = response as TVovDecodeStringResponse;
    yield put(vovDecodeStringAction.success(vovDecodeStringResponse));
    successCallback?.(vovDecodeStringResponse);
  } catch (err) {
    yield put(vovDecodeStringAction.failure(err));
    failedCallback?.(err);
  }
}
