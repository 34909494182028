import React from 'react';
import { navigate, useLocation } from '@reach/router';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Logo from '@/assets/images/logo.svg';
import Icon, { EIconColor } from '@/components/Icon';
import { TRootState } from '@/redux/reducers';

import { TSideBarProps } from './SideBar.types.d';
import { dataMenuBar } from './SideBar.data';
import './SideBar.scss';

const SideBar: React.FC<TSideBarProps> = () => {
  const myProfileState = useSelector((state: TRootState) => state.authReducer.getMyProfileResponse);
  const vovMyProfileState = useSelector((state: TRootState) => state.vovAuthReducer.vovGetMyProfileResponse);
  const { pathname } = useLocation();

  return (
    <div className="SideBar">
      <div className="SideBar-logo">
        <img src={Logo} alt="" />
      </div>
      <div className="SideBar-logo-info">Phần mềm chuyển đổi giọng nói thành văn bản</div>
      <div className="SideBar-list">
        {dataMenuBar({ userData: myProfileState, vovUserData: vovMyProfileState })
          .filter((item) => !item.hide)
          .map((item) => (
            <div
              key={item.id}
              className={classNames('SideBar-list-item flex items-center', {
                active: item.activePaths.includes(pathname),
                disabled: item.disabled,
              })}
              onClick={(): void => {
                if (!item.disabled) navigate(item.link);
              }}
            >
              <div className="SideBar-list-item-icon">
                <Icon name={item.iconName} color={EIconColor.DOVE_GRAY} />
              </div>
              <div className="SideBar-list-item-title ellipsis-1">{item.title}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideBar;
