import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { vovLoginAction } from '@/redux/actions';
import { vovLogin, TVovLoginResponse } from '@/services/vov-api';

// FUNCTION

export function* vovLoginSaga(action: ActionType<typeof vovLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(vovLogin, materials);
    const vovLoginResponse: TVovLoginResponse = response as TVovLoginResponse;
    yield put(vovLoginAction.success(vovLoginResponse));
    successCallback?.(vovLoginResponse);
  } catch (err) {
    yield put(vovLoginAction.failure(err));
    failedCallback?.(err);
  }
}
