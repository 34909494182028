import { all, takeLatest } from 'redux-saga/effects';

import { vovDecodeStringAction, vovGetMyProfileAction, vovLoginAction } from '@/redux/actions';

import { vovDecodeStringSaga } from './vov-decode-string';
import { vovGetMyProfileSaga } from './vov-get-my-profile';
import { vovLoginSaga } from './vov-login';

export default function* root(): Generator {
  yield all([
    takeLatest(vovDecodeStringAction.request.type, vovDecodeStringSaga),
    takeLatest(vovGetMyProfileAction.request.type, vovGetMyProfileSaga),
    takeLatest(vovLoginAction.request.type, vovLoginSaga),
  ]);
}
