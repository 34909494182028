import React from 'react';
import classNames from 'classnames';

import { TIconProps } from './Icon.types';
import { EIconName } from './Icon.enums';
import './Icon.scss';

import Microphone from './Microphone';
import DotsVertical from './DotsVertical';
import Settings from './Settings';
import Search from './Search';
import ListSearch from './ListSearch';
import Filter from './Filter';
import Plus from './Plus';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import Dots from './Dots';
import Edit from './Edit';
import Trash from './Trash';
import Category from './Category';
import Server from './Server';
import Copyright from './Copyright';
import Logout from './Logout';
import User from './User';
import Eye from './Eye';
import EyeOff from './EyeOff';
import X from './X';
import AlertCircleFill from './AlertCircleFill';
import AlertTriangleFill from './AlertTriangleFill';
import CircleCheckFill from './CircleCheckFill';
import CloudUpload from './CloudUpload';
import Menu from './Menu';
import Download from './Download';

const Icon: React.FC<TIconProps> = ({ name, className, color, style, onClick }) => {
  const renderIcon = (): React.ReactElement => {
    switch (name) {
      case EIconName.Microphone:
        return <Microphone color={color} />;
      case EIconName.DotsVertical:
        return <DotsVertical color={color} />;
      case EIconName.Settings:
        return <Settings color={color} />;
      case EIconName.Search:
        return <Search color={color} />;
      case EIconName.ListSearch:
        return <ListSearch color={color} />;
      case EIconName.Filter:
        return <Filter color={color} />;
      case EIconName.Plus:
        return <Plus color={color} />;
      case EIconName.ChevronDown:
        return <ChevronDown color={color} />;
      case EIconName.ChevronLeft:
        return <ChevronLeft color={color} />;
      case EIconName.ChevronRight:
        return <ChevronRight color={color} />;
      case EIconName.Dots:
        return <Dots color={color} />;
      case EIconName.Edit:
        return <Edit color={color} />;
      case EIconName.Trash:
        return <Trash color={color} />;
      case EIconName.Category:
        return <Category color={color} />;
      case EIconName.Server:
        return <Server color={color} />;
      case EIconName.Copyright:
        return <Copyright color={color} />;
      case EIconName.Logout:
        return <Logout color={color} />;
      case EIconName.User:
        return <User color={color} />;
      case EIconName.Eye:
        return <Eye color={color} />;
      case EIconName.EyeOff:
        return <EyeOff color={color} />;
      case EIconName.X:
        return <X color={color} />;
      case EIconName.CloudUpload:
        return <CloudUpload color={color} />;
      case EIconName.AlertCircleFill:
        return <AlertCircleFill color={color} />;
      case EIconName.AlertTriangleFill:
        return <AlertTriangleFill color={color} />;
      case EIconName.CircleCheckFill:
        return <CircleCheckFill color={color} />;
      case EIconName.Menu:
        return <Menu color={color} />;
      case EIconName.Download:
        return <Download color={color} />;

      default:
        return <></>;
    }
  };

  return (
    <div
      className={classNames('Icon', 'flex', 'justify-center', 'items-center', className)}
      style={style}
      onClick={onClick}
    >
      {renderIcon()}
    </div>
  );
};

export default Icon;
