import { createActionCreator } from 'deox';

import { TVovGetMyProfileMaterials, TVovGetMyProfileResponse } from '@/services/vov-api/vov-auth/vov-get-my-profile';

// CONSTANTS

export enum EVovGetMyProfileAction {
  VOV_GET_MY_PROFILE = 'VOV_GET_MY_PROFILE',
  VOV_GET_MY_PROFILE_REQUEST = 'VOV_GET_MY_PROFILE_REQUEST',
  VOV_GET_MY_PROFILE_SUCCESS = 'VOV_GET_MY_PROFILE_SUCCESS',
  VOV_GET_MY_PROFILE_FAILED = 'VOV_GET_MY_PROFILE_FAILED',
}

// TYPES

export type TVovGetMyProfileRequest = {
  type: EVovGetMyProfileAction.VOV_GET_MY_PROFILE_REQUEST;
  payload: {
    materials: TVovGetMyProfileMaterials;
    successCallback?: (response: TVovGetMyProfileResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVovGetMyProfileSuccess = {
  type: EVovGetMyProfileAction.VOV_GET_MY_PROFILE_SUCCESS;
  payload: { response: TVovGetMyProfileResponse };
};

export type TVovGetMyProfileFailed = { type: EVovGetMyProfileAction.VOV_GET_MY_PROFILE_FAILED };

// FUNCTION

export const vovGetMyProfileAction = {
  request: createActionCreator(
    EVovGetMyProfileAction.VOV_GET_MY_PROFILE_REQUEST,
    (resolve) =>
      (
        materials: TVovGetMyProfileMaterials,
        successCallback?: (response: TVovGetMyProfileResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVovGetMyProfileRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EVovGetMyProfileAction.VOV_GET_MY_PROFILE_SUCCESS,
    (resolve) =>
      (response: TVovGetMyProfileResponse): TVovGetMyProfileSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EVovGetMyProfileAction.VOV_GET_MY_PROFILE_FAILED,
    (resolve) =>
      (error: unknown): TVovGetMyProfileFailed =>
        resolve({ error }),
  ),
};
