import { TVovUser } from '@/common/models';
import VovApiService from '@/services/vov-api';

// TYPES

export type TVovGetMyProfileParams = unknown;

export type TVovGetMyProfileMaterials = {
  params?: TVovGetMyProfileParams;
};

export type TVovGetMyProfileResponse = TVovUser;

// FUNCTION

export const vovGetMyProfile = async ({ params }: TVovGetMyProfileMaterials): Promise<TVovGetMyProfileResponse> => {
  const response = await VovApiService.get(`/api/voice2text/getuserinfo`, { params });
  return response?.data;
};
