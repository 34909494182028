import { createActionCreator } from 'deox';

import { TVovDecodeStringMaterials, TVovDecodeStringResponse } from '@/services/vov-api/vov-auth/vov-decode-string';

// CONSTANTS

export enum EVovDecodeStringAction {
  VOV_DECODE_STRING = 'VOV_DECODE_STRING',
  VOV_DECODE_STRING_REQUEST = 'VOV_DECODE_STRING_REQUEST',
  VOV_DECODE_STRING_SUCCESS = 'VOV_DECODE_STRING_SUCCESS',
  VOV_DECODE_STRING_FAILED = 'VOV_DECODE_STRING_FAILED',
}

// TYPES

export type TVovDecodeStringRequest = {
  type: EVovDecodeStringAction.VOV_DECODE_STRING_REQUEST;
  payload: {
    materials: TVovDecodeStringMaterials;
    successCallback?: (response: TVovDecodeStringResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVovDecodeStringSuccess = {
  type: EVovDecodeStringAction.VOV_DECODE_STRING_SUCCESS;
  payload: { response: TVovDecodeStringResponse };
};

export type TVovDecodeStringFailed = { type: EVovDecodeStringAction.VOV_DECODE_STRING_FAILED };

// FUNCTION

export const vovDecodeStringAction = {
  request: createActionCreator(
    EVovDecodeStringAction.VOV_DECODE_STRING_REQUEST,
    (resolve) =>
      (
        materials: TVovDecodeStringMaterials,
        successCallback?: (response: TVovDecodeStringResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVovDecodeStringRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EVovDecodeStringAction.VOV_DECODE_STRING_SUCCESS,
    (resolve) =>
      (response: TVovDecodeStringResponse): TVovDecodeStringSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EVovDecodeStringAction.VOV_DECODE_STRING_FAILED,
    (resolve) =>
      (error: unknown): TVovDecodeStringFailed =>
        resolve({ error }),
  ),
};
