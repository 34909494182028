import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { vovGetMyProfileAction } from '@/redux/actions';
import { vovGetMyProfile, TVovGetMyProfileResponse } from '@/services/vov-api';

// FUNCTION

export function* vovGetMyProfileSaga(action: ActionType<typeof vovGetMyProfileAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(vovGetMyProfile, materials);
    const vovGetMyProfileResponse: TVovGetMyProfileResponse = response as TVovGetMyProfileResponse;
    yield put(vovGetMyProfileAction.success(vovGetMyProfileResponse));
    successCallback?.(vovGetMyProfileResponse);
  } catch (err) {
    yield put(vovGetMyProfileAction.failure(err));
    failedCallback?.(err);
  }
}
