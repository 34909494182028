import React from 'react';
import classNames from 'classnames';

import { TTagProps } from './Tag.types.d';
import './Tag.scss';

const Tag: React.FC<TTagProps> = ({ title, type }) => {
  return (
    <div className={classNames('Tag flex items-center justify-center', type)}>
      {title && <span className="Tag-title nowrap">{title}</span>}
    </div>
  );
};

export default Tag;
